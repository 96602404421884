import React, { useEffect, forwardRef, useState, useRef } from 'react';
import { createSelector } from 'reselect';
import { bytesToMegaBytes } from 'utils/formating';
import Chip from '@mui/material/Chip';
import SearchIcon from '@mui/icons-material/Search';
import LaunchIcon from '@mui/icons-material/Launch';
import { getHighlightedText } from 'utils/highlightText';
import SortHeader from './SortHeader';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGripVertical,
  faGripHorizontal,
  faLock,
  faLockOpen,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import T from 'i18n';
import classnames from 'classnames';
import { withResizeDetector } from 'react-resize-detector';
import { useSelector } from 'react-redux';
import {
  selectCurrentPageNumber,
  selectFetchDocumentsPending,
  selectParams,
} from 'common/selectors';
import history from 'common/history';
import { PopoverStickOnHover } from 'features/common';
import TableInfo from './TableInfo';
import { onCustomSortDate, onCustomSortNum, onCustomSortText } from './utils';

const mapCustomColumnsToNewValues = (columns: any) => {
  const getProperName = (col: string) => {
    switch (col) {
      case 'desc':
        return 'description';
      case 'date':
        return 'docDate';
      case 'discovery':
        return 'discoveryId';
      case 'fileType':
        return 'type';
      case 'pagesCount':
        return 'pageCount';
      case 'fileAuthor':
        return 'author';
      case 'fileRecipient':
        return 'recipient';
      default:
        return col;
    }
  };

  columns.forEach((col: any, index: number) => {
    columns[index] = getProperName(col);
  });

  return columns;
};

export default createSelector(
  (
    file: any,
    selectedColumns: any,
    selectedColumnsSmallView: any,
    { folders: { canSortDocuments = undefined } = {} }: any,
    sortMode: any,
    setSortMode: any,
    editMode: any,
    setEditMode: any,
    updateDocumentSort: any,
    idMinWidth: any,
    isTrialBookAgreed: any,
    isReadOnlyTrialbook: any,
    isQuickLinksPage: any,
    showGoToPageFilter: any,
    present: any,
    filter: any,
    currentFolder: any,
    isWiderDateColumn: any,
    isWiderColumns: any,
    isPresentModePage: any,
    isPresentModeWithBackEnd: any,
    isTagsFolder: any,
    setGoToPageNumber: any,
    setDocSearchVal: any,
    tableRef: any,
    setFile: any,
    canPresentToHearingRoom: boolean,
  ) => ({
    file,
    selectedColumns,
    selectedColumnsSmallView,
    canSortDocuments,
    sortMode,
    setSortMode,
    updateDocumentSort,
    idMinWidth,
    isTrialBookAgreed,
    isReadOnlyTrialbook,
    isQuickLinksPage,
    showGoToPageFilter,
    present,
    filter,
    currentFolder,
    isWiderDateColumn,
    isWiderColumns,
    isPresentModePage,
    isPresentModeWithBackEnd,
    isTagsFolder,
    setGoToPageNumber,
    setDocSearchVal,
    tableRef,
    setFile,
    canPresentToHearingRoom,
    editMode,
    setEditMode,
  }),
  ({
    file,
    selectedColumns,
    selectedColumnsSmallView,
    canSortDocuments,
    sortMode,
    setSortMode,
    updateDocumentSort,
    idMinWidth,
    isTrialBookAgreed,
    isReadOnlyTrialbook,
    isQuickLinksPage,
    showGoToPageFilter,
    present,
    filter,
    currentFolder,
    isWiderDateColumn,
    isWiderColumns,
    isPresentModePage,
    isPresentModeWithBackEnd,
    isTagsFolder,
    setGoToPageNumber,
    setDocSearchVal,
    tableRef,
    setFile,
    canPresentToHearingRoom,
    editMode,
  }) => {
    const columnsWidth = {
      index: 4,
      tab: 7,
      id: 10,
      description: 20,
      globalPages: 13,
      externalId: 12,
      discoveryId: 12,
      author: isWiderColumns ? 18 : 12,
      recipient: isWiderColumns ? 18 : 12,
      viewed: 12,
      dateViewed: 12,
      docDate: isWiderDateColumn ? 12 : 9,
      altDate: 12,
      pageCount: 9,
      type: 9,
      size: 9,
      info: 13,
    } as any;

    const restrictedColumnsInQuickLinksPage = [
      'externalId',
      'discoveryId',
      'docDate',
      'altDate',
      'description',
      'author',
      'recipient',
    ];

    let customColumns = (!file ? selectedColumns : selectedColumnsSmallView) as any;
    if (customColumns) {
      isTrialBookAgreed ? (customColumns[0] = 'tab') : (customColumns[0] = 'index');
      if (showGoToPageFilter || isPresentModePage)
        customColumns.includes('index') ? customColumns.push('tab') : customColumns.push('index');
      if (isQuickLinksPage && isTagsFolder) {
        customColumns = customColumns.filter(
          (col: any) => !restrictedColumnsInQuickLinksPage.includes(col),
        );
      }
    }

    // needed to keep backwards compatibility with case and user settings for custom columns
    mapCustomColumnsToNewValues(customColumns);

    const defaultColumns = [
      (!isTrialBookAgreed || showGoToPageFilter || isPresentModePage) && 'index',
      (isTrialBookAgreed || showGoToPageFilter || isPresentModePage) && 'tab',
      'id',
      'name',
      (showGoToPageFilter || isPresentModePage) && 'globalPages',
      isQuickLinksPage && isTagsFolder && 'viewed',
      'info',
    ].filter(Boolean);

    const getNameColumnWidth = (columns: any) => {
      return columns
        .filter((col: any) => col !== 'name')
        .reduce((acc: any, col: any) => acc + (columnsWidth[col] || 0), 0);
    };
    let columns = customColumns ? customColumns : defaultColumns;

    if (editMode) {
      const editableColumnIds = [
        'index',
        'tab',
        'docDate',
        'name',
        'author',
        'discoveryId',
        'externalId',
        'info',
        'id',
      ];
      columns = columns.filter((col: string) => editableColumnIds.includes(col));
    }

    return [
      {
        id: 'index',
        Header:
          canSortDocuments && canSortDocuments() && !isReadOnlyTrialbook
            ? () => {
                const icon = sortMode ? faLockOpen : faLock;
                return (
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 500, hide: 400 }}
                    overlay={
                      <Popover id="index">
                        <Popover.Content>
                          {sortMode
                            ? T.translate('case.exitSortMode')
                            : isTrialBookAgreed
                            ? T.translate('case.enterSortModePartial')
                            : T.translate('case.enterSortMode')}
                        </Popover.Content>
                      </Popover>
                    }
                  >
                    <>
                      <FontAwesomeIcon
                        style={{
                          marginBottom: '2px',
                          width: '100%',
                          minWidth: '10px',
                          ...(isTrialBookAgreed && { color: '#d57200b8' }),
                        }}
                        icon={icon}
                      />
                    </>
                  </OverlayTrigger>
                );
              }
            : null,
        sort: false,
        onClick:
          canSortDocuments &&
          canSortDocuments() &&
          !isReadOnlyTrialbook &&
          (({ setSortByColumn, setSortDirection, onSort, event }: any) => {
            setSortMode(!sortMode);
            setSortByColumn(null);
            setSortDirection(null);
            onSort({
              sortByColumn: null,
              sortDirection: null,
              event,
            });

            // unselect all rows when clicked - per request TV-380 as only possible solution atm
            tableRef.current?.unselectAllRows();
            event.stopPropagation();
          }),
        Cell: forwardRef(
          (
            {
              dragHandleProps,
              sortMode,
              editMode,
              isScrolling,
              rowIndex,
              rowHover,
              rowSelected,
              ...row
            }: any,
            ref,
          ) => {
            const icon = sortMode ? faGripHorizontal : faGripVertical;
            const dragOnlyLateInsertRow = isTrialBookAgreed && !row.agreed;
            const dragBundleRow = !isTrialBookAgreed || dragOnlyLateInsertRow;
            const showHandle =
              (rowHover || rowSelected) && (!sortMode || (sortMode && dragBundleRow));
            return (
              <div
                style={{
                  color: '#9A9A9A',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  height: '20px',
                }}
              >
                <div
                  {...dragHandleProps}
                  className={classnames('hover-change', {
                    draggable: !sortMode || (sortMode && dragBundleRow),
                  })}
                  ref={ref}
                  onClick={e => e.stopPropagation()}
                  style={{
                    marginBottom: '2px',
                    minWidth: '10px',
                    width: '10px',
                  }}
                >
                  <span className={classnames({ new: !showHandle, old: showHandle })}>
                    {isScrolling ? rowIndex + 1 : row.sortOrder}
                  </span>
                  <FontAwesomeIcon
                    className={classnames({ new: showHandle, old: !showHandle })}
                    icon={icon}
                  />
                </div>
              </div>
            );
          },
        ),
        forwardRef: true,
        minWidth: '50px',
        width: `${columnsWidth.index}rem`,
      },
      {
        Header: T.translate('case.tab'),
        id: 'tab',
        accessor: 'tab',
        minWidth: '80px',
        width: `${columnsWidth.tab}rem`,
        textAlign: 'center',
        Cell: (row: any) => {
          return !row.tab ? (
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Popover id="tabPopover" style={{ maxWidth: '350px', fontSize: '0.75rem' }}>
                  <React.Fragment>
                    <Popover.Content>{T.translate('case.noTabValMsg')}</Popover.Content>
                  </React.Fragment>
                </Popover>
              }
            >
              <FontAwesomeIcon style={{ color: '#ff9007' }} icon={faPencilAlt} />
            </OverlayTrigger>
          ) : (
            <div
              style={{
                ...(isTrialBookAgreed && !row.agreed && { color: '#d57200b8', fontWeight: 600 }),
              }}
            >
              {row.tab}
            </div>
          );
        },
        ...(sortMode && { sort: false }),
      },
      {
        id: 'id',
        Header: () => (
          <SortHeader
            text="case.documentId"
            onSortDownClick={() => updateDocumentSort('id', 'asc')}
            onSortUpClick={() => updateDocumentSort('id', 'desc')}
            sortMode={sortMode}
            columnId="id"
          />
        ),
        Cell: (row: any) => {
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: getHighlightedText(row.id, filter.term),
              }}
            ></div>
          );
        },
        minWidth: idMinWidth + 30, //adding 30 px for doc re-sequance(lock) issue
        width: `${columnsWidth.id}rem`,
        ...(sortMode && { sort: false }),
      },
      {
        Header: () => (
          <SortHeader
            text="case.name"
            onSortDownClick={() => updateDocumentSort('name', 'asc')}
            onSortUpClick={() => updateDocumentSort('name', 'desc')}
            sortMode={sortMode}
            columnId="name"
          />
        ),
        id: 'name',
        minWidth: '150px',
        width: editMode ? '100%' : `calc(100% - ${getNameColumnWidth(columns)}rem)`,
        Cell: withResizeDetector(({ width, height, ...row }: any) => {
          const [shouldShowTooltip, setShouldShowTooltip] = useState(false);
          const [goToPage, setGoTOPage] = useState(0);
          const isFetchDocumentsPending = useSelector(selectFetchDocumentsPending);
          const startPage = row.startPage && row.startPage.split('.');
          const lpSearch = filter.term && /^[0-9]{3,6}[/][0-9]{1,7}?$/.test(filter.term);
          const match = filter.term.match(/^[^1-9]*/);
          const gpSearch =
            !columns.includes('globalPages') &&
            startPage &&
            !row.highlights &&
            !row.matches &&
            filter.term &&
            row.globalPagePrefix.toLowerCase() === match[0].toLowerCase() &&
            /^[a-zA-Z]{1,2}[0-9.]{1,9}?$/.test(filter.term);
          const searchItem = gpSearch && filter.term && filter.term.length > 0 && filter.term;
          const searchIdx =
            (searchItem &&
              row &&
              row.globalPagePrefix &&
              searchItem.toLowerCase().replace(row.globalPagePrefix.toLowerCase(), '')) ||
            (lpSearch && filter.term && filter.term.split('/')[1]) ||
            '';

          const measuredRef = useRef<HTMLDivElement>(null);

          useEffect(() => {
            setShouldShowTooltip(
              measuredRef.current !== null &&
                measuredRef.current.offsetWidth < measuredRef.current.scrollWidth,
            );
          }, [width, height]);

          useEffect(() => {
            if (gpSearch) {
              if (searchIdx.includes('.') && startPage.length > 1) {
                const filterVal = searchIdx.split('.');
                setGoTOPage(parseInt(filterVal[1], 10) - parseInt(startPage[1], 10) + 1);
              } else {
                setGoTOPage(parseInt(searchIdx, 10) - parseInt(row.startPage) + 1);
              }
            } else if (lpSearch) setGoTOPage(parseInt(searchIdx, 10));
          }, [row, startPage, searchIdx, gpSearch, lpSearch]);

          return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 500, hide: 400 }}
                  trigger={shouldShowTooltip ? ['hover', 'focus'] : []}
                  overlay={
                    <Popover id="name" style={{ pointerEvents: 'none' }}>
                      <Popover.Content>{row.name || row.originalName}</Popover.Content>
                    </Popover>
                  }
                >
                  <div
                    ref={measuredRef}
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: getHighlightedText(row.name || row.originalName, filter.term),
                    }}
                  ></div>
                </OverlayTrigger>
                {((gpSearch && !isPresentModePage) || lpSearch) && !isFetchDocumentsPending && (
                  <Chip
                    color="primary"
                    icon={<LaunchIcon />}
                    style={{
                      height: 'auto',
                      flexFlow: 'row',
                      background: '#7BA0F4',
                    }}
                    label={gpSearch ? (!file ? `Page ${filter.term}` : 'Gp') : `Page ${searchIdx}`}
                    onClick={() => {
                      history.push(
                        history.location.pathname.replace(/\/files.*/, ``) +
                          `/files/${row.id}${
                            isPresentModePage ? `/compositeKey/${row.compositeKey}` : ``
                          }${
                            canPresentToHearingRoom &&
                            (!!present || isPresentModePage || isPresentModeWithBackEnd)
                              ? `?present=${
                                  isPresentModePage || isPresentModeWithBackEnd ? 'public' : present
                                }`
                              : ''
                          }`,
                      );
                      setGoToPageNumber(goToPage);
                      setFile(row);
                    }}
                  ></Chip>
                )}
                {(row.highlights || row.matches) && (
                  <PopoverStickOnHover
                    component={
                      <Popover.Content>
                        <PerfectScrollbar
                          options={{ suppressScrollX: true }}
                          style={{ maxHeight: '80vh' }}
                        >
                          {(row.matches && row.matches.length > 0
                            ? row.matches
                            : row.highlights
                          ).map((highlight: any, index: number) => {
                            function handleClick(e: any) {
                              const selectedText = getSelection()?.toString();
                              if (selectedText) return;
                              const parentRow = document.getElementById(row.compositeKey || row.id);
                              parentRow?.click();
                              history.push(
                                history.location.pathname.replace(/\/files.*/, ``) +
                                  `/files/${row.id}`,
                              );
                              setDocSearchVal(
                                row.matches && row.matches.length > 0 ? highlight.text : highlight,
                              );
                              setFile(row);
                            }
                            const strWithoutPhrase =
                              row.matches &&
                              row.matches.length > 0 &&
                              highlight.keyPhrase &&
                              highlight.text.split(highlight.keyPhrase);
                            return (
                              <div
                                key={index}
                                className="matchesItem"
                                style={{
                                  ...(index % 2 !== 0 && { backgroundColor: '#eff0f4' }),
                                }}
                                onClick={e => handleClick(e)}
                              >
                                {row.matches && row.matches.length > 0 ? (
                                  strWithoutPhrase && highlight.keyPhrase ? (
                                    <>
                                      {strWithoutPhrase[0]}
                                      {<b>{highlight.keyPhrase}</b>}
                                      {strWithoutPhrase[1]}
                                    </>
                                  ) : (
                                    highlight.text
                                  )
                                ) : (
                                  highlight
                                )}
                              </div>
                            );
                          })}
                        </PerfectScrollbar>
                      </Popover.Content>
                    }
                    placement="left"
                    onMouseEnter={() => {}}
                  >
                    <Chip
                      color="primary"
                      icon={<SearchIcon style={{ marginLeft: '0.5rem' }} />}
                      style={{
                        height: 'auto',
                        background: '#7BA0F4',
                      }}
                      label={Math.min(
                        99,
                        (row.matches && row.matches.length > 0 ? row.matches : row.highlights)
                          .length,
                      )}
                      onClick={() => {
                        history.push(
                          history.location.pathname.replace(/\/files.*/, ``) + `/files/${row.id}`,
                        );
                        setDocSearchVal(filter.term.replace(/^"(.*)"$/, '$1'));
                        setFile(row);
                      }}
                    ></Chip>
                  </PopoverStickOnHover>
                )}
              </div>
            </div>
          );
        }),
        accessor: (row: any) => row.name || row.originalName,
        ...(sortMode && { sort: false }),
      },
      {
        Header: () => (
          <SortHeader
            text="case.description"
            onSortDownClick={() => updateDocumentSort('description', 'asc', onCustomSortText)}
            onSortUpClick={() => updateDocumentSort('description', 'desc', onCustomSortText)}
            sortMode={sortMode}
            columnId="description"
          />
        ),
        id: 'description',
        minWidth: '210px',
        width: `${columnsWidth.description}rem`,
        Cell: withResizeDetector(({ width, height, ...row }: any) => {
          const [shouldShowTooltip, setShouldShowTooltip] = useState(false);

          const measuredDescRef = useRef<HTMLDivElement>(null);

          useEffect(() => {
            setShouldShowTooltip(
              measuredDescRef.current !== null &&
                measuredDescRef.current.offsetWidth < measuredDescRef.current.scrollWidth,
            );
          }, [width, height]);

          return (
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
              <OverlayTrigger
                placement="top"
                delay={{ show: 500, hide: 400 }}
                trigger={shouldShowTooltip ? ['hover', 'focus'] : []}
                overlay={
                  <Popover id="description" style={{ pointerEvents: 'none' }}>
                    <Popover.Content>{row.description}</Popover.Content>
                  </Popover>
                }
              >
                <div
                  ref={measuredDescRef}
                  style={{
                    width: `100%`,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    flexGrow: 1,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: getHighlightedText(row.description || '', filter.term),
                  }}
                ></div>
              </OverlayTrigger>
            </div>
          );
        }),
        accessor: (row: any) => row.description,
        onCustomSort: onCustomSortText,
        ...(sortMode && { sort: false }),
      },
      {
        id: 'globalPages',
        Header: () => (
          <SortHeader
            text="case.globalPages"
            onSortDownClick={() => updateDocumentSort('startPage', 'asc', onCustomSortNum)}
            onSortUpClick={() => updateDocumentSort('startPage', 'desc', onCustomSortNum)}
            sortMode={false}
            columnId="startPage"
          />
        ),
        minWidth: '130px',
        width: `${columnsWidth.globalPages}rem`,
        Cell: (row: any) => {
          const [goToPage, setGoTOPage] = useState(0);
          const currentOpenPage = useSelector(selectCurrentPageNumber);
          const { file: fileId, compositeKey } = useSelector(selectParams) as any;
          const isCurrentFile = row.compositeKey === compositeKey || row.id === fileId;
          const startPage = row.startPage && row.startPage.split('.');
          const match = filter.term.match(/^[^1-9]*/); //spliting filter term into GP-Prefix and numbers
          const isGPFilter =
            startPage &&
            (filter.goToGlobalPageIdx ||
              (filter.term &&
              row.globalPagePrefix.toLowerCase() === match[0].toLowerCase() && //check if gp-prefix exactly matches filter term start of filter-term
                /^[a-zA-Z]{1,2}[0-9.]{1,9}?$/.test(filter.term)));
          const filterTerm =
            (filter.goToGlobalPageIdx
              ? filter.goToGlobalPageIdx
              : filter.term &&
                row &&
                filter.term.toLowerCase().replace(row.globalPagePrefix.toLowerCase(), '')) || '';

          useEffect(() => {
            if (isGPFilter)
              if (filterTerm.includes('.') && startPage.length > 1) {
                const filterVal = filterTerm.split('.');
                setGoTOPage(parseInt(filterVal[1], 10) - parseInt(startPage[1], 10) + 1);
              } else {
                setGoTOPage(parseInt(filterTerm, 10) - parseInt(row.startPage) + 1);
              }
          }, [row, startPage, isGPFilter, filterTerm]);
          return isGPFilter && (goToPage !== currentOpenPage || !isCurrentFile) ? (
            <Chip
              color="primary"
              icon={<LaunchIcon />}
              style={{
                height: 'auto',
                background: '#7BA0F4',
              }}
              label={'Page ' + row.globalPagePrefix + filterTerm}
              onClick={() => {
                history.push(
                  history.location.pathname.replace(/\/files.*/, ``) +
                    `/files/${row.id}${
                      isPresentModePage ? `/compositeKey/${row.compositeKey}` : ``
                    }${
                      !!present || isPresentModePage
                        ? `?present=${isPresentModePage ? 'public' : present}`
                        : ''
                    }`,
                );
                if (filterTerm) setGoToPageNumber(goToPage);
                setFile(row);
              }}
            ></Chip>
          ) : (
            `${row.globalPagePrefix}${row.startPage} - ${row.globalPagePrefix}${row.lastPage}`
          );
        },
        onCustomSort: onCustomSortNum,
        ...(sortMode && { sort: false }),
      },
      {
        Header: () => (
          <SortHeader
            text={'case.externalId'}
            onSortDownClick={() => updateDocumentSort('externalId', 'asc')}
            onSortUpClick={() => updateDocumentSort('externalId', 'desc')}
            sortMode={sortMode}
            columnId="externalId"
          />
        ),
        id: 'externalId',
        minWidth: '140px',
        width: `${columnsWidth.externalId}rem`,
        accessor: (row: any) => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 500, hide: 400 }}
            trigger={row.externalId && row.externalId.length > 18 && ['hover', 'focus']}
            overlay={
              <Popover id="externalId" style={{ pointerEvents: 'none' }}>
                <Popover.Content>{row.externalId}</Popover.Content>
              </Popover>
            }
          >
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                flexGrow: 1,
              }}
            >
              {row.externalId}
            </div>
          </OverlayTrigger>
        ),
        onCustomSort: onCustomSortText,
        ...(sortMode && { sort: false }),
      },
      {
        Header: () => (
          <SortHeader
            text={'case.discoveryId'}
            onSortDownClick={() => updateDocumentSort('discoveryId', 'asc')}
            onSortUpClick={() => updateDocumentSort('discoveryId', 'desc')}
            sortMode={sortMode}
            columnId="discoveryId"
          />
        ),
        id: 'discoveryId',
        minWidth: '140px',
        width: `${columnsWidth.discoveryId}rem`,
        accessor: (row: any) => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 500, hide: 400 }}
            trigger={row.discoveryId && row.discoveryId.length > 18 && ['hover', 'focus']}
            overlay={
              <Popover id="discoveryId" style={{ pointerEvents: 'none' }}>
                <Popover.Content>{row.discoveryId}</Popover.Content>
              </Popover>
            }
          >
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                flexGrow: 1,
              }}
              dangerouslySetInnerHTML={{
                __html: getHighlightedText(row.discoveryId || '', filter.term),
              }}
            ></div>
          </OverlayTrigger>
        ),
        onCustomSort: onCustomSortText,
        ...(sortMode && { sort: false }),
      },
      {
        Header: () => (
          <SortHeader
            text="case.author"
            onSortDownClick={() => updateDocumentSort('author', 'asc')}
            onSortUpClick={() => updateDocumentSort('author', 'desc')}
            sortMode={sortMode}
            columnId="author"
          />
        ),
        id: 'author',
        minWidth: isWiderColumns ? '190px' : '130px',
        width: `${columnsWidth.author}rem`,
        accessor: (row: any) => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 500, hide: 400 }}
            trigger={row.author && row.author.length > 18 && ['hover', 'focus']}
            overlay={
              <Popover id="author" style={{ pointerEvents: 'none' }}>
                <Popover.Content>{row.author}</Popover.Content>
              </Popover>
            }
          >
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                flexGrow: 1,
              }}
            >
              {row.author}
            </div>
          </OverlayTrigger>
        ),
        onCustomSort: onCustomSortText,
        ...(sortMode && { sort: false }),
      },
      {
        Header: () => (
          <SortHeader
            text="case.recipient"
            onSortDownClick={() => updateDocumentSort('recipient', 'asc')}
            onSortUpClick={() => updateDocumentSort('recipient', 'desc')}
            sortMode={sortMode}
            columnId="recipient"
          />
        ),
        id: 'recipient',
        minWidth: isWiderColumns ? '190px' : '130px',
        width: `${columnsWidth.recipient}rem`,
        accessor: (row: any) => (
          <OverlayTrigger
            placement="top"
            delay={{ show: 500, hide: 400 }}
            trigger={row.recipientsList && row.recipientsList.length > 1 && ['hover', 'focus']}
            overlay={
              <Popover id="recipient" style={{ pointerEvents: 'none' }}>
                <Popover.Content>{row.recipientsList?.join(',')}</Popover.Content>
              </Popover>
            }
          >
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                flexGrow: 1,
              }}
            >
              {row.recipientsList?.join(',')}
            </div>
          </OverlayTrigger>
        ),
        onCustomSort: onCustomSortText,
        ...(sortMode && { sort: false }),
      },
      {
        id: 'viewed',
        Header: () => (
          <SortHeader
            text="case.viewed"
            onSortDownClick={() => updateDocumentSort('viewed', 'asc')}
            onSortUpClick={() => updateDocumentSort('viewed', 'desc')}
            sortMode={sortMode}
            columnId="viewed"
          />
        ),
        accessor: (row: any) => row.viewed && moment(row.viewed).fromNow(),
        minWidth: '130px',
        width: `${columnsWidth.viewed}rem`,
        ...(sortMode && { sort: false }),
      },
      {
        id: 'dateViewed',
        Header: () => (
          <SortHeader
            text={
              currentFolder && currentFolder.code === 'recently-viewed'
                ? 'case.dateViewed'
                : 'case.datePresented'
            }
            sortMode={false}
          />
        ),
        accessor: (row: any) => row.viewed && moment(row.viewed).format('DD-MM-YYYY HH:mm:ss'),
        minWidth: '130px',
        width: `${columnsWidth.dateViewed}rem`,
        onCustomSort: onCustomSortNum,
        ...(sortMode && { sort: false }),
      },
      {
        id: 'docDate',
        Header: () => (
          <SortHeader
            text="case.docDate"
            onSortDownClick={() => updateDocumentSort('docDate', 'asc', onCustomSortDate)}
            onSortUpClick={() => updateDocumentSort('docDate', 'desc', onCustomSortDate)}
            sortMode={sortMode}
            columnId="docDate"
          />
        ),
        accessor: 'docDate',
        minWidth: isWiderDateColumn ? '150px' : '100px',
        width: `${columnsWidth.docDate}rem`,
        onCustomSort: onCustomSortDate,
        ...(sortMode && { sort: false }),
      },
      {
        id: 'altDate',
        Header: () => (
          <SortHeader
            text="case.alternativeDate"
            onSortDownClick={() => updateDocumentSort('altDate', 'asc')}
            onSortUpClick={() => updateDocumentSort('altDate', 'desc')}
            sortMode={sortMode}
            columnId="altDate"
          />
        ),
        accessor: 'altDate',
        minWidth: '150px',
        width: `${columnsWidth.altDate}rem`,
        onCustomSort: onCustomSortDate,
        ...(sortMode && { sort: false }),
      },
      {
        id: 'type',
        Header: () => (
          <SortHeader
            text="case.type"
            onSortDownClick={() => updateDocumentSort('type', 'asc')}
            onSortUpClick={() => updateDocumentSort('type', 'desc')}
            sortMode={sortMode}
            columnId="type"
          />
        ),
        minWidth: '100px',
        width: `${columnsWidth.type}rem`,
        accessor: 'type',
        ...(sortMode && { sort: false }),
      },
      {
        id: 'pageCount',
        Header: () => (
          <SortHeader
            text="case.pageCount"
            onSortDownClick={() => updateDocumentSort('pageCount', 'asc')}
            onSortUpClick={() => updateDocumentSort('pageCount', 'desc')}
            sortMode={sortMode}
            columnId="pageCount"
          />
        ),
        minWidth: '110px',
        width: `${columnsWidth.pageCount}rem`,
        accessor: 'pageCount',
        onCustomSort: onCustomSortNum,
        ...(sortMode && { sort: false }),
      },
      {
        id: 'size',
        Header: () => (
          <SortHeader
            text="case.size"
            onSortDownClick={() => updateDocumentSort('size', 'asc', onCustomSortNum)}
            onSortUpClick={() => updateDocumentSort('size', 'desc', onCustomSortNum)}
            sortMode={sortMode}
            columnId="size"
          />
        ),
        minWidth: '100px',
        width: `${columnsWidth.size}rem`,
        accessor: (row: any) => (row.size ? bytesToMegaBytes(row.size) : '0 MB'),
        onCustomSort: onCustomSortNum,
        ...(sortMode && { sort: false }),
      },
      {
        Header: () => <SortHeader text="case.info" sortMode={false} />,
        Cell: (row: any) => (
          <TableInfo
            row={row}
            customColumns={customColumns ? customColumns : []}
            filter={filter.term}
            present={present}
            isPresentModePage={isPresentModePage}
          />
        ),
        id: 'info',
        minWidth: '120px',
        width: `${columnsWidth.info}rem`,
        onCustomSort: (
          a: any,
          b: any,
          {
            duplicate,
            source,
            hasPublicHyperlinks,
            hasAnnotations,
            isPrivate,
            notes,
            showMoreInfoIcon,
            tags,
            attachments,
            locations,
            ...rest
          }: any,
          {
            duplicate: duplicateB,
            source: sourceB,
            hasPublicHyperlinks: hasPublicHyperlinksB,
            hasAnnotations: hasAnnotationsB,
            isPrivate: isPrivateB,
            notes: notesB,
            showMoreInfoIcon: showMoreInfoIconB,
            tags: tagsB,
            attachments: attachmentsB,
            locations: locationsB,
          }: any,
        ) => {
          let comparison = 0;
          if (duplicate) ++comparison;
          if (source) ++comparison;
          if (hasPublicHyperlinks) ++comparison;
          if (hasAnnotations) ++comparison;
          if (isPrivate) ++comparison;
          if (notes) ++comparison;
          if (showMoreInfoIcon) ++comparison;
          if (tags && tags.length > 0) ++comparison;
          if (attachments && attachments.length > 0) ++comparison;
          if (locations && locations.length > 0) ++comparison;
          if (duplicateB) --comparison;
          if (sourceB) --comparison;
          if (hasPublicHyperlinksB) --comparison;
          if (hasAnnotationsB) --comparison;
          if (isPrivateB) --comparison;
          if (notesB) --comparison;
          if (showMoreInfoIconB) --comparison;
          if (tagsB && tagsB.length > 0) --comparison;
          if (attachmentsB && attachmentsB.length > 0) --comparison;
          if (locationsB && locationsB.length > 0) --comparison;
          return comparison;
        },
        ...(sortMode && { sort: false }),
      },
    ].filter(col => columns.includes(col.id));
  },
);
